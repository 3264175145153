import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';
@Injectable(
  { providedIn: 'root' }
)
export class DatosGeneralesService {
  public identity: any;//Se guardarán los datos del usuario
  public listaProcesosRefrescar: any = [];
  public msgs: Message[] = [];
  public formularioBloqueado: boolean;
  public calendarioEspaniol = {
    firstDayOfWeek: 1,
    dayNamesMin: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
  };
  public MENSAJEERRORESTANDAR = 'Ocurrió un error inesperado, por favor contáctese con el administrador del sistema';

  public urlBase = 'https://ingresos.haciendapasto.gov.co/siscar_reportes_consolidado/';
  public urlCarga: string = 'https://ingresos.haciendapasto.gov.co:9203'
  public urlDescarga: string = 'https://ingresos.haciendapasto.gov.co/Documentos/';
  public urlReporteExcelColbus: string = 'https://ingresos.haciendapasto.gov.co/siscar_reportes_consolidado/Librerias/base_reportes/reporte_excel_form_dac.php';
  public urlReporteExcelConsulta: string = 'https://ingresos.haciendapasto.gov.co/siscar_reportes_consolidado/Librerias/base_reportes/reporte_excel_dac.php';
  public urlReporteExcelConsultaCabecera: string = 'https://ingresos.haciendapasto.gov.co/siscar_reportes_consolidado/Librerias/base_reportes/reporte_excel_head_dac.php';
  public urlReporteExcelConsultaMultihoja: string = 'https://ingresos.haciendapasto.gov.co/siscar_reportes_consolidado/Librerias/base_reportes/reporte_excel_multihoja_dac.php';
  public urlReporteExcelDashboard: string = 'https://ingresos.haciendapasto.gov.co/siscar_reportes_consolidado/Librerias/base_reportes/reporte_excel_dashboard.php';
  public usuarioAuthExterno = "siscarConsolidado@datasoft.com.co";
  public sistema = 1;//1 Siscar, 2 Safith,3 Datapagos
  public nombreIdentity: string = 'identitySiscarRentasPasto';
  public nombreIdentityAyuda: string = 'ayudaSiscarRentasPasto';
  public rangoFechas: string = '1980:2035';

  constructor(private messageService: MessageService) {
  }

  //Método que guarda en el localstorage los datos de la sesion
  setIdentity(objeto: any) {
    localStorage.setItem(this.nombreIdentity, JSON.stringify(objeto));
  }

  //Método para limpial del localstorage los datos
  deleteIdentity() {
    localStorage.removeItem(this.nombreIdentity);
  }

  cambiarCodigoIdentity(codigo: string) {
    let identity = JSON.parse(localStorage.getItem(this.nombreIdentity));
    identity.codigoSesion = codigo;
    localStorage.setItem(this.nombreIdentity, JSON.stringify(identity));
  }

  //Metodo para recibir los datos del local storage
  getIdentity() {
    let identity = JSON.parse(localStorage.getItem(this.nombreIdentity));
    if (identity && identity != "undefined") {
      this.identity = identity;
    } else {
      this.identity = null;
    }
    return this.identity;
  }

  setAyuda(valor: boolean) {
    localStorage.setItem(this.nombreIdentityAyuda, valor.toString());
  }

  getAyuda() {
    return JSON.parse(localStorage.getItem(this.nombreIdentityAyuda));;
  }

  deleteAyuda() {
    localStorage.removeItem(this.nombreIdentityAyuda);
  }

  getUrlCarga() {
    return this.urlCarga;
  }

  getUrlDescarga() {
    return this.urlDescarga;
  }

  getUrlBase() {
    return this.urlBase;
  }

  getUrlExcelColbus() {
    return this.urlReporteExcelColbus;
  }

  getUrlExcelConsulta() {
    return this.urlReporteExcelConsulta;
  }

  getUrlExcelConsultaCabecera() {
    return this.urlReporteExcelConsultaCabecera;
  }

  getUrlExcelConsultaMultihoja() {
    return this.urlReporteExcelConsultaMultihoja;
  }

  getReporteExcelDashboard() {
    return this.urlReporteExcelDashboard;
  }

  getSistema() {
    return this.sistema;
  }

  getUsuarioAuthExterno() {
    return this.usuarioAuthExterno;
  }

  insertarProcesoLista(idmenu: string, idproceso: string) {
    this.listaProcesosRefrescar.push({ 'idmenu': idmenu, 'idproceso': idproceso });
  }

  eliminarProcesoListaMenu(idmenu: string) {//Elimina los procesos cuando se cierra un formulario desde el tab
    let i = 0;
    let idProceso: number;
    while (i < this.listaProcesosRefrescar.length) {
      if (this.listaProcesosRefrescar[i].idmenu == idmenu) {
        idProceso = this.listaProcesosRefrescar[i].idproceso;
        clearInterval(idProceso);
        this.listaProcesosRefrescar.splice(i, 1);
        i = this.listaProcesosRefrescar.length;
      }
      i++;
    }
  }

  eliminarProcesoListaProceso(idproceso: number) {
    let i = 0;
    while (i < this.listaProcesosRefrescar.length) {
      if (this.listaProcesosRefrescar[i].idproceso == idproceso) {
        clearInterval(idproceso);
        this.listaProcesosRefrescar.splice(i, 1);
        i = this.listaProcesosRefrescar.length;
      }
      i++;
    }
  }

  mostrarMensajePersonalizado(tipo: string, mensaje: string) {
    if (tipo == 'mensaje_error') {
      this.enviarMensaje('E', mensaje);
    } else if (tipo == 'mensaje_alerta') {
      this.enviarMensaje('A', mensaje);
    } else if (tipo == 'mensaje_informacion') {
      this.enviarMensaje('I', mensaje);
    } else if (tipo == 'mensaje_exito') {
      this.enviarMensaje('C', mensaje);
    }
  }


  enviarMensaje(tipo: string, mensaje: string) {
    if (tipo == 'E') {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
    } else if (tipo == 'A') {
      this.messageService.add({ severity: 'warn', summary: 'Alerta', detail: mensaje });
    } else if (tipo == 'I') {
      this.messageService.add({ severity: 'info', summary: 'Información', detail: mensaje });
    } else {
      this.messageService.add({ severity: 'success', summary: 'Correcto', detail: mensaje });
    }
  }

  getMensajes() {
    return this.msgs;
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  getMensajeError() {
    return this.MENSAJEERRORESTANDAR;
  }

  getCalendarioEspaniol() {
    return this.calendarioEspaniol;
  }

  getRangoFechas() {
    return this.rangoFechas;
  }

  getFormularioBloqueado() {
    return this.formularioBloqueado;
  }

  setFormularioBloqueado(bloqueado: boolean) {
    this.formularioBloqueado = bloqueado;
  }
}
